import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import { Layout } from "../components";
import { BriefNav, Thanks } from "../components/brief";
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  SubmitStep,
} from "../components/brief/kampania-kompleksowa";

const BriefBranding = ({ location: { pathname } }) => {
  const {
    register,
    errors,
    handleSubmit,
    triggerValidation,
    formState,
  } = useForm({
    mode: "onChange",
  });
  const [step, set] = useState(1);

  const setStep = (step) => {
    set(step);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    setTimeout(() => {
      axios
        .post(
          "https://adream-mailing.herokuapp.com/send-brief-branding-when",
          data
        )
        .then((res) => {
          console.log("Wysłane...");
          navigate("/brief-kampania-kompleksowa-dziekujemy-za-kontakt");
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1500);
  };
  const stepsData = {
    step,
    setStep,
    register,
    formState,
    triggerValidation,
    errors,
  };
  return (
    <Layout
      seo={{
        title: "Brief - Kampania kompleksowa",
        href: pathname,
        lang: "pl",
      }}
    >
      <section className="container-fluid brief_main_section steps">
        <h1>Brief – poznajmy się lepiej!</h1>
        <BriefNav currentStep={step} setStep={setStep} steps={4} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <input
            type="checkbox"
            aria-label="bot-check"
            name="bot_field"
            ref={register}
            className="bot-field"
          />
          <FirstStep {...stepsData} />
          <SecondStep {...stepsData} />
          <ThirdStep {...stepsData} />
          <SubmitStep {...stepsData} />
          {step === 5 && <Thanks />}
        </form>
      </section>
    </Layout>
  );
};

export default BriefBranding;
