import React from "react";
import { Radio, Textarea } from "../..";
import $ from "jquery";

if (typeof window !== `undefined`) {
  $("input[type=radio][name=promotion]").change(function () {
    if (
      this.value === "Jest obecnie promowana za pomocą Google Ads" ||
      this.value === "Była wcześniej promowana, ale obecnie nie jest"
    ) {
      $(".adwords-access-row").css("display", "flex");
    } else if (this.value === "Nigdy nie była promowana") {
      $(".adwords-access-row").css("display", "none");
    }
  });

  $("input[type=radio][name=socialPromotion]").change(function () {
    if (
      this.value === "Kampania Facebook Ads jest prowadzona" ||
      this.value === "Kampania była prowadzona, ale obecnie nie jest"
    ) {
      $(".facebook-ads-access").css("display", "flex");
    } else if (this.value === "Kampania nigdy nie była prowadzona") {
      $(".facebook-ads-access").css("display", "none");
    }
  });
}

const ThirdStep = ({ register, errors, step, setStep }) => {
  return (
    <div
      className="step_3 stepWrapper"
      style={{ display: step === 3 ? "block" : "none" }}
    >
      <h3 className="step-title">Doświadczenie z kanałami marketingowymi</h3>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Działania marketingowe</h4>
          <p>
            Jakie działania marketingowe (nie tylko online) były prowadzone
            przez Ciebie do tej pory?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="marketing_actions"
            label="Działania marketingowe"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Skuteczność działań marketingowych</h4>
          <p>
            Które z dotychczasowych działań reklamowych były skuteczne i
            dlaczego?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="marketing_actions_success"
            label="Skuteczne działania marketingowe"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Promowanie</h4>
          <p>
            Czy Twoja strona internetowa jest obecnie promowana poprzez Google
            Ads (AdWords) ?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="promotion"
            label="Jest obecnie promowana za pomocą Google Ads"
            id="promotion_yes"
            register={register}
            errors={null}
            className="radio-for-adwords"
          />
          <Radio
            name="promotion"
            label="Nigdy nie była promowana"
            id="promotion_no"
            register={register}
            errors={null}
            className="radio-for-adwords"
          />
          <Radio
            name="promotion"
            label="Była wcześniej promowana, ale obecnie nie jest"
            id="promotion_past"
            register={register}
            errors={null}
            className="radio-for-adwords"
          />
        </div>
      </div>
      <div className="row brief_row adwords-access-row hidden-row">
        <div className="col-md-5">
          <h4>Czy posiadasz dostęp do konta Google Ads (AdWords)</h4>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="adwordsAccess"
            id="adwordsAccess_yes"
            label="Tak"
            register={register}
            errors={null}
          />
          <Radio
            name="adwordsAccess"
            id="adwordsAccess_no"
            label="Nie"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Google Analytics</h4>
          <p>Czy posiadasz dostęp do konta Google Analytics</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="analytics_access"
            id="analytics_access_yes"
            label="Tak"
            register={register}
            errors={null}
          />
          <Radio
            name="analytics_access"
            id="analytics_access_no"
            label="Nie"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Słowa kluczowe</h4>
          <p>
            Czy chciałbyś, aby Twoja witryna była widoczna na jakieś wybrane
            słowa kluczowe?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="key_words"
            label="Słowa kluczowe"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <p>
            Czy Twoja strona internetowa/ strona na Facebooku jest obecnie
            promowana poprzez kampanie Facebook Ads?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="socialPromotion"
            id="socialPromotion_yes"
            label="Kampania Facebook Ads jest prowadzona"
            register={register}
            errors={null}
          />
          <Radio
            name="socialPromotion"
            id="socialPromotion_no"
            label="Kampania nigdy nie była prowadzona"
            register={register}
            errors={null}
          />
          <Radio
            name="socialPromotion"
            id="socialPromotion_past"
            label="Kampania była prowadzona, ale obecnie nie jest"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row facebook-ads-access hidden-row">
        <div className="col-md-5">
          <h4>Dostęp do konta Facebook Ads</h4>
          <p>Czy posiadasz dostęp do konta reklamowego Facebook Ads?</p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Radio
            name="facebookAdsAccess"
            id="facebookAdsAccess_yes"
            label="Tak"
            register={register}
            errors={null}
          />
          <Radio
            name="facebookAdsAccess"
            id="facebookAdsAccess_no"
            label="Nie"
            register={register}
            errors={null}
          />
        </div>
      </div>
      <div className="row brief_row">
        <div className="col-md-5">
          <h4>Marketing automation</h4>
          <p>
            Czy korzystasz z narzędzia do marketing automation? Jeżeli tak, to z
            jakiego? Jak dużą bazę mailingową posiadasz?
          </p>
        </div>
        <div className="col-md-6 offset-md-1">
          <Textarea
            cols="40"
            rows="3"
            name="marketing_automation"
            label="Marketing automation"
            register={register}
            required={true}
            errors={errors}
          />
        </div>
      </div>
      <div className="btn-brief-group">
        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(2)}
        >
          poprzedni <strong>krok</strong>
        </button>

        <button
          type="button"
          className="btn btn-box"
          onClick={() => setStep(4)}
        >
          kolejny <strong>krok</strong>
        </button>
      </div>
    </div>
  );
};

export default ThirdStep;
